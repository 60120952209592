import React from "react";
import _ from "lodash";

import DateRange from "../components/DateRange";
import Employer from "../components/Employer";

import { Layout } from "../components/index";
import { htmlToReact, safePrefix, getPage, getAccessLevel } from "../utils";
import { OutboundLink } from "gatsby-plugin-google-analytics";

export default class Project extends React.Component {
  render() {
    let level = getAccessLevel("level");
    let employer, employer_relativePath;
    if (level < 2) {
      employer = false;
    } else {
      employer_relativePath = this.props.pageContext.frontmatter.workplace.replace(
        /src\/pages\//,
        ""
      );
      employer = getPage(this.props.pageContext.pages, employer_relativePath);
    }

    // console.log('im projects ', this.props.pageContext.frontmatter.workplace);
    return (
      <Layout {...this.props}>
        <article className="project project-full">
          <header className="project-header">
            <h1 className="project-title underline">
              {_.get(this.props, "pageContext.frontmatter.title")}
            </h1>
          </header>
          {_.get(this.props, "pageContext.frontmatter.subtitle") && (
            <div className="project-subtitle">
              {htmlToReact(
                _.get(this.props, "pageContext.frontmatter.subtitle")
              )}
            </div>
          )}
          <div className="project-upper">
            <div className="project-content">
              <p>{_.get(this.props, "pageContext.frontmatter.excerpt")}</p>

              {_.get(this.props, "pageContext.frontmatter.tools") && (
                <cite>
                  {"Tools und Methoden: " +
                    htmlToReact(
                      _.get(this.props, "pageContext.frontmatter.tools")
                    )}
                </cite>
              )}
              {_.get(this.props, "pageContext.frontmatter.project_url") && (
                <p>
                  <br />
                  <OutboundLink
                    href={safePrefix(
                      _.get(this.props, "pageContext.frontmatter.project_url")
                    )}
                    target="_blank"
                    rel="noopener"
                  >
                    {_.get(this.props, "pageContext.frontmatter.title")}
                  </OutboundLink>
                </p>
              )}
            </div>
            {_.get(this.props, "pageContext.frontmatter.content_img_path") && (
              <div className="project-thumbnail">
                <img
                  src={safePrefix(
                    _.get(
                      this.props,
                      "pageContext.frontmatter.content_img_path"
                    ).replace(/\.png|\.jpg$/, ".webp")
                  )}
                  alt={_.get(this.props, "pageContext.frontmatter.title")}
                />
              </div>
            )}
          </div>
          <div className="project-content">
            {htmlToReact(_.get(this.props, "pageContext.html"))}
          </div>
          {_.get(this.props, "pageContext.frontmatter.start") && (
            <footer className="project-meta">
              <DateRange {...this.props.pageContext}>
                Projektzeitraum:{" "}
              </DateRange>
            </footer>
          )}
          <div>{employer && <Employer pageContext={employer}></Employer>}</div>
        </article>
      </Layout>
    );
  }
}
